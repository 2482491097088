import Link from 'next/link';
import useTranslation from 'next-translate/useTranslation';

const PolicyLinks = () => {
  const { t } = useTranslation('common');

  return (
    <div className='policy-links'>
      <span>
        <Link href='https://arkestro.com/privacy-policy'>
          <a>{t('general.privacy_policy')}</a>
        </Link>
      </span>
      <div className='divider'>|</div>
      <span>
        <a href='https://arkestro.com/terms-of-use'>{t('general.terms_of_use')}</a>
      </span>
    </div>
  );
};

export default PolicyLinks;
